<template>
  <div class="contact" id="js-contact">
    <div class="thanks inner">
      <p class="thanks__ttl">
        お問い合わせ<br class="spOnly" />ありがとうございました。
      </p>
      <div class="thanks__txt">
        <p class="thanks__txt__para">
          フォームの送信が完了しました。
        </p>
        <p class="thanks__txt__para">
          お客様より送信されたお問い合わせ内容をご確認後、
          <br />担当者より折り返しご連絡をさせていただきます。
          <br />なお、一週間ほど経過しても弊社からの連絡が<br
            class="spOnly"
          />無い場合は、 <br /><span class="ftCBlue"
            >お手数ですが再度送信いただくか、</span
          >
          <br /><span class="ftCBlue"
            >お電話（03-6327-7409）にてご連絡いただけますと幸いです。</span
          >
        </p>
        <p class="thanks__txt__para">
          ※土曜・日曜・祝日、年末年始・夏季休暇期間は、
          <br />翌営業日以降のご対応とさせていただきます。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import scrollAnimMixin from '../../mixins/scrollAnimMixin.js'
export default {
  mixins: [scrollAnimMixin],
  head: {
    title: {
      inner: 'お問い合わせありがとうございました。'
    },
    meta: [
      {
        property: 'og:title',
        content: 'お問い合わせありがとうございました。｜GENBA SHIKO'
      },
      {
        name: 'description',
        content: 'お問い合わせありがとうございました。'
      },
      {
        property: 'og:description',
        content: 'お問い合わせありがとうございました。'
      },
      {
        property: 'og:url',
        content: 'https://luci.co.jp/genbashiko/contact/thanks'
      },
      { property: 'og:type', content: 'article' }
    ]
  }
}
</script>
<style lang="scss" scoped>
.thanks {
  text-align: center;
  padding: 160px 0 140px;
  &__ttl {
    font-size: 3.5rem;
  }
  &__txt {
    margin-top: 80px;
    font-size: 2rem;
    line-height: 1.8;
    &__para {
      margin-top: 40px;
    }
    .ftCBlue {
      color: #ff9e16;
      font-weight: 700;
    }
  }
}
@media only screen and (max-width: 768px) {
  .thanks {
    padding: 60px 0 70px;
    &__ttl {
      font-size: 2rem;
      line-height: 1.7;
    }
    &__txt {
      margin-top: 30px;
      font-size: 1.3rem;
      &__para {
        margin-top: 15px;
      }
      .ftCBlue {
      }
    }
  }
}
</style>
